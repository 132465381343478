export type Job = {
  ReportID: string;
  ReportServer: string;
  ReportPath: string;
  ReportSQL: string;
  To: string;
  From: string;
  CC: string;
  BCC: string;
  Subject: string;
  Body: string;
  FileName: string;
  FileType: string;
  JobTitle: string;
  JobDescription: string;
  CronSchedule: string;
  ReportTitle: string;
  ReportSubTitle: string;
  ReportHeader: string;
  ReportFooter: string;
  Parameters: string;
  Environment:string;
  SchedulerState:string;
  TriggerPaused:string;
  };

  export type reportFilter = {
    Id: string;
    Value: string;
    };
      
export const environment = [
  'PRE_IC',
  'NMG_IC',
  'NEV_IC',
  'SMG_IC',
  'APL_IC',
  'NASB_IC',
  'ABL_IC',
  'NNF_IC',
  'AEG_IC',
  'TSS_IC',
  'DIV_IC',
  'CIG_IC',
  'CRS_IC',
  'PremierConnect_IC',
  'WMA_IC',
  'MAS_IC',
  'SMS_IC',
  'BER_IC',
  'WAP_IC',
  'GAR_STG',
  'SMS_STG',
  'SAI_IC',
  'GAR_IC',
  'PAL_IC',
  'PGM_IC',
  'SIS_IC',
  'AMS_IC'
]
  
export const pdfTypes = [
  'EXCELOPENXML',
  'CSV'
] 
    
export const schedulerOptions = [
  'Save',
  'SaveAndRun'
] 